import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { signupReducer } from "./components/signup/SignupReducer";
import { loginReducer } from "./components/login/LoginReducer";
import questsReducer from "./components/quests/QuestsReducer";
import leaderboardReducer from "./components/leaderboards/LeaderboardReducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // only auth will be persisted
};

const browserHistory = createBrowserHistory();

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: browserHistory });

const rootReducer = combineReducers({
  router: routerReducer,
  createUser: signupReducer,
  auth: loginReducer,
  quests: questsReducer,
  leaderboards: leaderboardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(routerMiddleware),
});

export const persistor = persistStore(store);
export const history = createReduxHistory(store);