import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_USER_ERROR,
  CREATE_USER_SUBMITTED,
  CREATE_USER_SUCCESS
} from "./SignupTypes";

export const signupNewUser = userData => {
  return async dispatch => {
    dispatch({ type: CREATE_USER_SUBMITTED });
    try {
      const response = await axios.post("/api/v1/users/", {
        username: userData.username,
        password: userData.password,
        re_password: userData.password
      });
      toast.success(
        "Account for " +
          userData.username +
          " created successfully. Logging you in..."
      );
      dispatch({ type: CREATE_USER_SUCCESS });
      return response.data; // Return the response data
    } catch (error) {
      if (error.response) {
        toast.error(JSON.stringify(error.response.data));
        dispatch({
          type: CREATE_USER_ERROR,
          errorData: error.response.data
        });
      } else if (error.message) {
        toast.error(JSON.stringify(error.message));
      } else {
        toast.error(JSON.stringify(error));
      }
      throw error; // Rethrow the error so it can be caught in the component
    }
  };
};
