import { 
    FETCH_USER_LEADERBOARD_REQUEST, 
    FETCH_USER_LEADERBOARD_SUCCESS, 
    FETCH_USER_LEADERBOARD_FAILURE 
  } from './LeaderboardTypes';
  
  const initialState = {
    data: {
      leaderboard: null,
      records: []
    },
    loading: false,
    error: null
  };
  
  export const leaderboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_LEADERBOARD_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_USER_LEADERBOARD_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null
        };
      case FETCH_USER_LEADERBOARD_FAILURE:
        return {
          ...state,
          data: { leaderboard: null, records: [] },
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default leaderboardReducer;