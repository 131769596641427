import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../login/LoginActions';
import QuestsList from '../quests/QuestsList';
import SpaceBackground from '../SpaceBackground';

const Dashboard = ({ auth, logout }) => {

  return (
    <>
      <SpaceBackground />
      <div className="relative min-h-screen text-white font-sans">
        <main className="max-w-7xl mx-auto p-6">
          <QuestsList />
        </main>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Dashboard);