import React, { useState, useEffect} from 'react';
import { Shield, Info } from 'lucide-react';
import Toast from './ui/Toast';
import axios from 'axios';  // Add this import

const SettingsPage = () => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // Add this effect to fetch initial privacy setting
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/v1/profile/');
        setIsPrivate(response.data.is_private);
      } catch (error) {
        setToastMessage('Failed to load settings');
        setShowToast(true);
      }
    };
    fetchSettings();
  }, []);

  const handlePrivacyToggle = async () => {
    try {
      const newState = !isPrivate;
      // Make API call to update privacy setting
      await axios.patch('/api/v1/profile/', {
        is_private: newState
      });
      
      setIsPrivate(newState);
      const message = newState 
        ? "Your profile is now private" 
        : "Your profile is now public";
      setToastMessage(message);
      setShowToast(true);
    } catch (error) {
      setToastMessage('Failed to update privacy settings');
      setShowToast(true);
    }
  };

  // Rest of your component remains the same...

  return (
    <div className="min-h-screen text-white font-sans">
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-gray-800 bg-opacity-80 rounded-xl p-8 border border-gray-700">
          {/* Header */}
          <h1 className="text-3xl font-bold mb-8 text-blue-400 flex items-center">
            <Shield className="mr-3" />
            Settings
          </h1>

          {/* Privacy Toggle Section */}
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h2 className="text-xl font-semibold mb-1">Profile Privacy</h2>
                <p className="text-gray-400 text-sm">Control who can see your profile information</p>
              </div>
              <button
                onClick={handlePrivacyToggle}
                className="relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300"
                style={{
                  backgroundColor: isPrivate ? '#3B82F6' : '#374151'
                }}
              >
                <span
                  className={`inline-block w-4 h-4 transform transition-transform duration-300 bg-white rounded-full ${
                    isPrivate ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>

            {/* Info Block */}
            <div className="bg-gray-700 bg-opacity-50 rounded-lg p-4 flex items-start space-x-3">
              <Info size={20} className="text-blue-400 mt-1 flex-shrink-0" />
              <p className="text-sm text-gray-300">
                By making your profile private, no one will be able to see your information, 
                including your quest submissions, rewards earned, and achievements. 
                You'll still appear on leaderboards, but your profile will be inaccessible.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {showToast && (
        <Toast 
          message={toastMessage} 
          type="success" 
          onClose={() => setShowToast(false)} 
        />
      )}
    </div>
  );
};

export default SettingsPage;