import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import QuestInList from './QuestInList';
import { fetchQuests, likeQuest, setActiveTab } from './QuestsActions';

const QuestsList = ({ 
  fetchQuests, 
  likeQuest,
  setActiveTab,
  quests,
  loading, 
  error, 
  isAuthenticated,
  activeTab
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  
  const finishedQuests = useSelector(state => state.quests.finishedQuests);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  // Initial fetch when component mounts
  useEffect(() => {
    if (activeTab === "finished" && finishedQuests.length === 0) {
      fetchMoreFinishedQuests();
    } else {
      fetchQuests(activeTab === "active");
    }
  }, []);

  // Fetch when tab changes
  useEffect(() => {
    if (activeTab === "active") {
      fetchQuests(true);
    } else if (finishedQuests.length === 0) {
      fetchMoreFinishedQuests();
    }
  }, [activeTab]);

  const handleLikeClick = async (questId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  
    try {
      await likeQuest(questId);
    } catch (error) {
      console.error('Error liking quest:', error);
    }
  };

  const fetchMoreFinishedQuests = useCallback(async () => {
    if (isLoadingMore || !hasMore) return;
  
    setIsLoadingMore(true);
    setLoadingError(null);
    
    try {
      const response = await axios.get(`/api/v1/quests/?page=${page}&active=false&inactive_only=true`);
      const newQuests = response.data.results || [];
      
      if (newQuests.length === 0) {
        setHasMore(false);
      } else {
        // Ensure each quest has required fields before dispatching
        const processedQuests = newQuests.map(quest => ({
          ...quest,
          prefix: quest.prefix || `finished-${quest.id}`, // Fallback prefix if not present
        }));

        dispatch({ 
          type: 'ADD_FINISHED_QUESTS', 
          payload: processedQuests 
        });
        setHasMore(!!response.data.next);
        setPage(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error fetching finished quests:', error);
      setLoadingError(error.message);
      setHasMore(false);
    } finally {
      setIsLoadingMore(false);
    }
  }, [page, isLoadingMore, hasMore, dispatch]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "finished" && finishedQuests.length === 0) {
      setPage(1);
      setHasMore(true);
      setLoadingError(null);
      fetchMoreFinishedQuests();
    }
  };

  useEffect(() => {
    if (inView && activeTab === "finished" && !isLoadingMore && hasMore) {
      fetchMoreFinishedQuests();
    }
  }, [inView, activeTab, isLoadingMore, hasMore, fetchMoreFinishedQuests]);

  if (loading && !finishedQuests.length) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4 rounded-lg bg-red-100">
        Error loading quests: {error}
      </div>
    );
  }

  const questsToDisplay = quests?.results || [];

  const renderQuests = (questsList) => {
    return questsList.map(quest => {
      // Ensure quest has all required props before rendering
      if (!quest || !quest.id) {
        console.warn('Invalid quest data:', quest);
        return null;
      }
      
      return (
        <QuestInList 
          key={quest.id} 
          quest={quest} 
          isAuthenticated={isAuthenticated}
          onLikeClick={handleLikeClick}
        />
      );
    }).filter(Boolean); // Remove null entries
  };

  return (
      <div className="max-w-4xl mx-auto px-2 md:px-4">
        <h1 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-center text-blue-400">Quests</h1>
        
        <div className="flex space-x-1 mb-6 md:mb-8 bg-gray-800 p-1 rounded-lg mx-1 md:mx-0">
          <button
            onClick={() => handleTabChange("active")}
            className={`flex-1 py-2 md:py-3 px-4 md:px-6 rounded-md text-sm font-medium transition-all duration-200 ${
              activeTab === "active"
                ? "bg-blue-500 text-white"
                : "text-gray-400 hover:text-white hover:bg-gray-700"
            }`}
          >
            Active Quests
          </button>
        <button
          onClick={() => handleTabChange("finished")}
          className={`flex-1 py-3 px-6 rounded-md text-sm font-medium transition-all duration-200 ${
            activeTab === "finished"
              ? "bg-blue-500 text-white"
              : "text-gray-400 hover:text-white hover:bg-gray-700"
          }`}
        >
          Finished Quests
        </button>
      </div>

      <div className="space-y-4 md:space-y-6">
        {activeTab === "active" ? (
          questsToDisplay.length === 0 ? (
            <div className="text-center text-gray-400 py-8">
              No active quests available at the moment
            </div>
          ) : (
            renderQuests(questsToDisplay)
          )
        ) : (
          <>
            {renderQuests(finishedQuests)}
            
            {loadingError && (
              <div className="text-center text-red-500 p-4">
                Error loading finished quests: {loadingError}
              </div>
            )}
            
            {hasMore && !loadingError && (
              <div ref={ref} className="text-center py-4">
                {isLoadingMore ? (
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                ) : (
                  <div className="text-gray-400">Scroll for more</div>
                )}
              </div>
            )}
            
            {!hasMore && finishedQuests.length > 0 && !loadingError && (
              <div className="text-center py-4 text-gray-400">
                No more finished quests to load
              </div>
            )}
            
            {finishedQuests.length === 0 && !isLoadingMore && !loadingError && (
              <div className="text-center text-gray-400 py-8">
                No finished quests available
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  quests: state.quests.quests,
  loading: state.quests.loading,
  error: state.quests.error,
  isAuthenticated: state.auth.isAuthenticated,
  activeTab: state.quests.activeTab
});

export default connect(
  mapStateToProps, 
  { fetchQuests, likeQuest, setActiveTab }
)(QuestsList);