import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './login/LoginActions';
import { LogOut, Settings } from 'lucide-react';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    setIsDropdownOpen(false);
    navigate('/');
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      timeoutRef.current = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 150);
    }
  };

  const handleClick = () => {
    if (isMobile) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      navigate(`/u/${user.username}`);
    }
  };

  useEffect(() => {
    if (isMobile) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isMobile]);

  const NavIcon = ({ src, alt, text, to }) => (
    <Link to={to} className="flex flex-col items-center text-center group">
      <div className="relative w-10 h-10 mb-1">
        <img 
          src={src} 
          alt={alt} 
          className="w-full h-full transition-all duration-300 group-hover:scale-120 filter brightness-0 invert opacity-80 group-hover:brightness-100 group-hover:invert-0 group-hover:opacity-100" 
        />
      </div>
      <span className="text-xs font-medium text-gray-400 group-hover:text-yellow-400 transition-colors duration-300">{text}</span>
    </Link>
  );

  return (
    <nav className="bg-gray-900 bg-opacity-60 p-4 border-b border-purple-950 shadow-lg">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-8">
          <NavIcon src="/cosmic_quests_icon.png" alt="Home" text="Home" to="/" />
          {isAuthenticated && (
            <>
              <NavIcon src="/quests_icon.png" alt="Quests" text="Quests" to="/dashboard" />
              <NavIcon src="/feed_icon.png" alt="Feed" text="Feed" to="/feed" />
              <NavIcon src="/leaderboards_icon.png" alt="Leaderboards" text="Leaderboards" to="/leaderboard" />
            </>
          )}
        </div>
        {isAuthenticated && user ? (
          <div 
            className="relative" 
            ref={dropdownRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button 
              onClick={handleClick}
              className="flex items-center text-gray-400 hover:text-blue-400 transition-colors duration-300 group cursor-pointer"
            >
              <div className="relative w-8 h-8 mr-2">
                <img 
                  src="/user_profile_icon.png" 
                  alt="User Profile" 
                  className="w-full h-full transition-all duration-300 group-hover:scale-120 filter brightness-0 invert opacity-80 group-hover:brightness-100 group-hover:invert-0 group-hover:opacity-100"
                />
              </div>
              <span className="text-sm font-medium text-gray-400 group-hover:text-yellow-400 transition-colors duration-300">{user.username}</span>
            </button>
            {isDropdownOpen && (
              <div 
                className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-10 border border-gray-700"
                style={{ marginTop: '0.5rem', padding: '0.25rem 0' }}
              >
                <Link to={`/u/${user.username}`} className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 hover:text-yellow-400 transition-colors duration-300 flex items-center">
                  <img 
                    src="/user_profile_icon.png" 
                    alt="User Profile" 
                    className="w-4 h-4 mr-2 filter brightness-0 invert opacity-80 group-hover:brightness-100 group-hover:invert-0 group-hover:opacity-100"
                  />
                  User Profile
                </Link>
                <Link to="/settings" className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 hover:text-yellow-400 transition-colors duration-300 flex items-center">
                  <Settings size={16} className="mr-2" />
                  Settings
                </Link>
                <button 
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 hover:text-yellow-400 transition-colors duration-300 flex items-center"
                >
                  <LogOut size={16} className="mr-2" />
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="space-x-4">
            <Link to="/login" className="text-sm font-medium text-gray-400 hover:text-blue-400 transition-colors duration-300">Login</Link>
            <Link to="/signup" className="text-sm font-medium text-gray-400 hover:text-blue-400 transition-colors duration-300">Sign Up</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;