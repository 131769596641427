import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { submitQuest, fetchSingleQuest,
  likeQuest,
  trackQuestView } from './QuestsActions';
import { Users, Heart, Eye, HelpCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/Alert';
import Toast from '../ui/Toast';

const PUBLIC_PATH = '/';
const MILLISECONDS_BEFORE_ADDING_VIEW = 5000;

const QuestDetail = ({ 
  quest: propQuest, 
  onHome = false, 
  isAuthenticated, 
  guestAnswer, 
  onGuestSubmit,
  containerClassName = ''
}) => {
  const { questId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quests = useSelector(state => state.quests.quests);
  const questsState = useSelector(state => state.quests.quests);
  const [userAnswer, setUserAnswer] = useState('');
  const [previousAnswer, setPreviousAnswer] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [hasTrackedView, setHasTrackedView] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const viewTimeoutRef = useRef(null);
  const [charCount, setCharCount] = useState(userAnswer?.length || 0);
  const [showTypeInfo, setShowTypeInfo] = useState(false);
  
  const { questSlug } = useParams();
  useEffect(() => {
    console.log('INN USE EFFECT')
    console.log(propQuest)
    console.log(questSlug)
    if (!propQuest && questSlug) {
      console.log('Fetching quest with slug:', questSlug);
      fetchSingleQuest(questSlug)
        .then(data => console.log('Fetched quest data:', data))
        .catch(err => console.error('Error fetching quest:', err));
    }
  }, [propQuest, questSlug]);

  console.log('QuestDetail render:', { 
    hasPropsQuest: !!propQuest, 
    onHome, 
    questId: useParams().questId,
    storeQuestCount: quests.length 
  });
  
  const quest = useMemo(() => (
    propQuest || questsState?.results?.find(q => q.id === parseInt(questId)) || {}
  ), [propQuest, questsState?.results, questId]);

  // Add view tracking after 10 seconds
  useEffect(() => {
    if (quest?.id && isAuthenticated && !hasTrackedView) {
      viewTimeoutRef.current = setTimeout(() => {
        dispatch(trackQuestView(quest.id))
          .then(() => setHasTrackedView(true))
          .catch(console.error);
      }, MILLISECONDS_BEFORE_ADDING_VIEW);
  
      return () => {
        if (viewTimeoutRef.current) {
          clearTimeout(viewTimeoutRef.current);
        }
      };
    }
  }, [quest?.id, isAuthenticated, hasTrackedView, dispatch]);

  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  
    if (isLiking) return;
  
    setIsLiking(true);
    try {
      await dispatch(likeQuest(quest.id));
    } catch (error) {
      setToastMessage('Failed to update like status');
      setShowToast(true);
    } finally {
      setIsLiking(false);
    }
  };

  const getQuestTypeDescription = (type) => {
    const descriptions = {
      "question": "You can answer in a couple of sentences or write an essay. Articulate your thoughts clearly and bring your unique perspective to get high rewards.",
      "continue_story": "Build upon a proposed story beginning; go wherever your mind wanders but try to stay consistent with the established plot.",
      "connections": "Identify and explain intriguing links between seemingly unrelated elements. Present your perspective clearly, supporting your discoveries with logical arguments and relevant facts."
    };
    return descriptions[type] || "";
  };

  const getQuestTypeIcon = (type) => {
    switch (type) {
      case 'question':
        return '❓';
      case 'continue_story':
        return '📝';
      case 'connections':
        return '🔗';
      default:
        return '❓';
    }
  };

  const getQuestTypeName = (type) => {
    switch (type) {
      case 'question':
        return 'Question';
      case 'continue_story':
        return 'Story';
      case 'connections':
        return 'Connections';
      default:
        return 'Unknown Type';
    }
  };

  const renderStatsGrid = () => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 p-4 bg-gray-800 bg-opacity-50 rounded-lg">
      <div className="flex items-center justify-center space-x-2">
        <img 
          src={`${PUBLIC_PATH}speck_icon.png`}
          alt="Reward" 
          className="w-6 md:w-8 h-6 md:h-8 brightness-100 opacity-100"
        />
        <span className="text-sm md:text-base text-gray-300">{quest.base_reward} SPECKS</span>
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Users size={16} className="text-gray-400 md:w-5 md:h-5" />
        <span className="text-sm md:text-base text-gray-300">{quest.completions} completed</span>
      </div>
      <div className="flex items-center justify-center space-x-2 cursor-pointer" onClick={handleLikeClick}>
        <Heart 
          size={16}
          className={`md:w-5 md:h-5 ${quest.is_liked ? 'text-red-500 fill-red-500' : 'text-gray-400'} 
            transition-colors duration-300 ${isAuthenticated ? 'cursor-pointer hover:scale-110' : 'cursor-not-allowed'}`}
        />
        <span className="text-sm md:text-base text-gray-300">{quest.likes_count || 0}</span>
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Eye size={16} className="text-gray-400 md:w-5 md:h-5" />
        <span className="text-sm md:text-base text-gray-300">{quest.views_count || 0}</span>
      </div>
    </div>
  );

  const fetchQuestData = useCallback(() => {
    if (!questSlug && !quest?.id) {
      return Promise.reject(new Error('No quest identifier available'));
    }
  
    console.log('Fetching quest info:', questSlug || quest.id);
    return dispatch(fetchSingleQuest(questSlug || quest.id))
      .then(data => {
        if (data.user_submission) {
          setPreviousAnswer(data.user_submission);
          setUserAnswer(data.user_submission);
          setIsEditing(false);
        }
        return data;
      })
      .catch(err => {
        setError(err.message || 'An error occurred while fetching the quest.');
        throw err;
      });
  }, [dispatch, questSlug, quest?.id]);

  useEffect(() => {
    if (!propQuest && questSlug) {
      fetchQuestData();
    }
  }, [propQuest, questSlug, fetchQuestData]);

  useEffect(() => {
    if (!propQuest && questId && !questsState?.results?.some(q => q.id === parseInt(questId))) {
      // Only fetch if we don't have the quest data either from props or in the store
      fetchQuestData();
    }
  }, [propQuest, questId, questsState?.results, fetchQuestData]);

  console.log("QuestDetail render:", { quest, isAuthenticated, previousAnswer, userAnswer, isEditing });
  
  useEffect(() => {
    console.log("QuestDetail useEffect:", { quest, isAuthenticated, previousAnswer });
    if (isAuthenticated && (quest.user_submission || previousAnswer)) {
      setPreviousAnswer(quest.user_submission || previousAnswer);
      setUserAnswer(quest.user_submission || previousAnswer);
      setIsEditing(false);
    } else if (!isAuthenticated && guestAnswer) {
      setUserAnswer(guestAnswer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quest, isAuthenticated, quest.user_submission, guestAnswer]);

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('Submitting answer:', userAnswer);
  if (isAuthenticated) {
    try {
      await dispatch(submitQuest(quest.id, userAnswer));
      setPreviousAnswer(userAnswer);
      setIsEditing(false);
      setToastMessage('Your response has been successfully submitted!');
      setShowToast(true);
      
      // Only fetch updated data if we're on the quest page
      if (questSlug) {
        fetchQuestData();
      }
    } catch (error) {
      setError(error.message || 'An error occurred while submitting the quest.');
      setToastMessage('Failed to submit response. Please try again.');
      setShowToast(true);
    }
  } else {
    if (typeof onGuestSubmit === 'function') {
      onGuestSubmit(userAnswer);
    } else {
      localStorage.setItem('guestAnswer', userAnswer);
      localStorage.setItem('guestQuestId', quest.id);
      navigate('/signup');
    }
  }
};

  const questContent = (
    <div className={`w-full flex justify-center font-sans ${containerClassName}`}>
      <div className={`relative w-full ${onHome ? 'max-w-4xl' : 'max-w-6xl'}`}>
        {/* Subtle border container */}
        <div className="absolute -inset-[1px] overflow-hidden rounded-xl">
          <div 
            className="absolute inset-0 rounded-xl"
            style={{
              background: `
                linear-gradient(
                  135deg,
                  rgba(30, 64, 175, 0.1),
                  rgba(67, 56, 202, 0.2),
                  rgba(109, 40, 217, 0.3),
                  rgba(147, 51, 234, 0.2),
                  rgba(30, 64, 175, 0.1)
                )
              `,
              boxShadow: `
                inset 0 0 12px rgba(67, 56, 202, 0.1),
                0 0 4px rgba(109, 40, 217, 0.1)
              `
            }}
          />
        </div>
      {/* Main content */}
      <div className={`relative w-full ${onHome ? 'max-w-4xl' : 'max-w-6xl'} min-h-[600px] bg-gray-900 p-8 rounded-xl z-10`}>
        {error && (
          <Alert variant="destructive">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <div className="w-full flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
            <h2 className="text-3xl md:text-4xl font-bold break-words max-w-full">
              {onHome ? (
                <Link 
                  to={`/quest/${quest.slug}`}
                  className="bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent hover:opacity-80 transition-opacity"
                >
                  {quest.quest_title}
                </Link>
              ) : (
                <span className="bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent">
                  {quest.quest_title}
                </span>
              )}
            </h2>
            
            <div className="flex flex-wrap items-center gap-3 md:ml-auto">
              {quest.quest_type && (
                <div 
                  className="relative group self-start md:self-center"
                  onMouseEnter={() => setShowTypeInfo(true)}
                  onMouseLeave={() => setShowTypeInfo(false)}
                >
                  <div className="flex items-center bg-indigo-900 hover:bg-indigo-800 rounded-full px-3 py-1.5 text-sm cursor-help shadow-lg transition-all duration-200">
                    <span className="mr-2">{getQuestTypeIcon(quest.quest_type)}</span>
                    <span className="text-gray-200">{getQuestTypeName(quest.quest_type)}</span>
                    <HelpCircle className="w-4 h-4 ml-1 text-gray-300" />
                  </div>
                  
                  {showTypeInfo && (
                    <div className="absolute z-10 w-72 p-4 bg-indigo-950 rounded-lg shadow-xl border border-indigo-700 top-full right-0 mt-2">
                      <div className="relative">
                        <div className="absolute -top-2 right-4 w-4 h-4 bg-indigo-950 border-l border-t border-indigo-700 transform rotate-45"></div>
                      </div>
                      <p className="text-gray-200 text-sm">{getQuestTypeDescription(quest.quest_type)}</p>
                    </div>
                  )}
                </div>
              )}
              <div className="flex items-center space-x-2 bg-gray-800 px-4 py-2 rounded-full self-start md:self-center">
                <img 
                  src={`${PUBLIC_PATH}speck_icon.png`}
                  alt="Specks" 
                  className="w-6 md:w-8 h-6 md:h-8 brightness-100 opacity-100"
                />
                <span className="text-base md:text-lg font-semibold text-gray-300">
                  {quest.base_reward}
                </span>
                </div>
            </div>
          </div>
        </div>

        <p className="text-lg text-gray-300 mb-8">{quest.quest_text}</p>

        {/* Textarea section */}
        <div className="relative mb-8">
          <textarea
            className={`w-full p-6 bg-gray-800 text-white rounded-lg resize-none min-h-[320px] border border-gray-700 focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 ${
              isAuthenticated && previousAnswer && !isEditing ? 'filter blur-[2px]' : ''
            }`}
            value={userAnswer}
            onChange={e => {
              const newValue = e.target.value;
              if (newValue.length <= quest.max_chars) {
                setUserAnswer(newValue);
                setCharCount(newValue.length);
              }
            }}
            maxLength={quest.max_chars}
            placeholder="Enter your answer here..."
            disabled={((isAuthenticated && previousAnswer && !isEditing) || (!quest.is_active && isEditing))}
            readOnly={!quest.is_active && isEditing}
          ></textarea>

            {/* Character count display */}
            <div className="absolute bottom-2 right-4 text-sm text-gray-400">
              <span className={charCount === quest.max_chars ? 'text-yellow-400' : ''}>
                {charCount}
              </span>
              <span> / {quest.max_chars}</span>
            </div>
          
          {isAuthenticated && previousAnswer && !isEditing && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-800 bg-opacity-70 rounded-lg">
              <p className="text-center text-white mb-4 px-4">
                {quest.is_active 
                  ? "You've already submitted your response to this quest. Would you like to modify it?"
                  : "This quest has ended. Would you like to view your submitted response?"}
              </p>
              <button
                onClick={() => setIsEditing(true)}
                className={`font-bold py-2 px-6 rounded-lg transition duration-300 ${
                  quest.is_active
                    ? "bg-purple-800 hover:bg-purple-700 transition duration-300 transform hover:scale-105 text-white"
                    : "bg-purple-800 hover:bg-purple-700 transition duration-300 transform hover:scale-105 text-white"
                }`}
              >
                {quest.is_active ? "Edit Response" : "View Response"}
              </button>
            </div>
          )}

          {isEditing && !quest.is_active && (
            <div className="mt-6 flex justify-center">
              <button
                onClick={() => setIsEditing(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
              >
                Close
              </button>
            </div>
          )}
        </div>

        {/* Stats grid */}
        {renderStatsGrid()}
          
        {(!isAuthenticated || !previousAnswer || isEditing) && (
          <div className="mt-6 flex justify-center">
            <button
              onClick={handleSubmit}
              className={`
                py-3 px-8 rounded-lg font-bold text-lg
                bg-gradient-to-r from-purple-700 to-purple-800
                hover:from-purple-800 hover:to-purple-900
                text-white transition duration-300 transform hover:scale-105
                disabled:opacity-50 disabled:cursor-not-allowed
                shadow-lg
              `}
              disabled={!quest.is_active}
            >
              {isAuthenticated && previousAnswer ? 'Update Answer' : 'Submit Answer'}
            </button>
          </div>
        )}

        {showToast && (
          <Toast 
            message={toastMessage} 
            type={toastMessage.includes('Failed') ? 'error' : 'success'}
            onClose={() => setShowToast(false)} 
          />
        )}
      </div>
    </div>
    </div>
  );

  return questContent;
};

export default QuestDetail;