import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const requireAuth = (WrappedComponent) => {
  const AuthenticatedComponent = (props) => {
    const location = useLocation();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem("token");

    useEffect(() => {
      // Add a timeout to prevent infinite loading
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // 2 seconds timeout

      return () => clearTimeout(timer);
    }, []);

    // Check if token is expired
    const isTokenValid = () => {
      if (!token) return false;
      try {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        return tokenData.exp * 1000 > Date.now();
      } catch (error) {
        console.error('Error validating token:', error);
        localStorage.removeItem("token"); // Clear invalid token
        return false;
      }
    };

    if (isAuthenticated === null && token && isLoading) {
      return (
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          <p className="ml-3 text-lg text-gray-600">Verifying authentication...</p>
        </div>
      );
    }

    if (!isAuthenticated && (!token || !isTokenValid())) {
      const redirectAfterLogin = encodeURIComponent(location.pathname);
      // Redirect to signup if user has never logged in, otherwise to login
      const redirectPath = !token ? '/signup' : `/login?next=${redirectAfterLogin}`;
      return <Navigate to={redirectPath} replace />;
    }

    return <WrappedComponent {...props} isAuthenticated={isAuthenticated || !!token} />;
  };

  return AuthenticatedComponent;
};

export default requireAuth;
