import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserLeaderboard } from './LeaderboardActions';
import { Trophy, User, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import SpaceBackground from '../SpaceBackground';

const UserLeaderboard = () => {
  const dispatch = useDispatch();
  const leaderboardState = useSelector(state => state.leaderboards);
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const userRowRef = useRef(null);

  useEffect(() => {
    dispatch(fetchUserLeaderboard());
  }, [dispatch]);

  useEffect(() => {
    if (userRowRef.current) {
      userRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [leaderboardState.data]);

  const renderContent = () => {
    if (!leaderboardState || !leaderboardState.data) {
      return <div className="text-white text-center py-8">Loading leaderboard...</div>;
    }

    const { data, loading, error } = leaderboardState;

    if (loading) {
      return <div className="text-white text-center py-8">Loading leaderboard...</div>;
    }

    if (error) {
      return <div className="text-center py-8">{error}</div>;
    }

    if (!data.leaderboard || !data.records) {
      return (
        <div className="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold mb-4">No Active Leaderboard</h2>
          <p>There is no active leaderboard at the moment.</p>
          <p className="mt-4">Complete quests to join a leaderboard and see your ranking!</p>
        </div>
      );
    }

    const { leaderboard, records } = data;

    return (
      <>
        <p className="text-xl mb-8 font-sans">{leaderboard.description}</p>
        <div className="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 flex items-center">
            <Trophy className="mr-2 text-yellow-400" />
            {leaderboard.title}
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left">
                  <th className="py-2 px-4 w-1/6">Rank</th>
                  <th className="py-2 px-4 w-3/6">Explorer</th>
                  <th className="py-2 px-4 w-2/6">Score</th>
                </tr>
              </thead>
              <tbody>
                {records.map((record, index) => {
                  const isCurrentUser = isAuthenticated && user && record.user.username === user.username;
                  return (
                    <tr 
                      key={record.id}
                      ref={isCurrentUser ? userRowRef : null}
                      className={`
                        ${isCurrentUser ? 'bg-blue-500 bg-opacity-50' : index % 2 === 0 ? 'bg-gray-700 bg-opacity-50' : ''}
                      `}
                    >
                      <td className="py-2 px-4 w-1/6">
                        <div className="flex items-center">
                          {record.user_rank === 1 && <Star className="mr-2 text-yellow-400" />}
                          <span>{record.user_rank}</span>
                        </div>
                      </td>
                      <td className="py-2 px-4 w-3/6">
                        <div className="flex items-center">
                          <User className="mr-2" />
                          <Link 
                            to={`/u/${record.user.username}`}
                            className="text-blue-400 hover:text-blue-300 transition-colors duration-300"
                          >
                            {record.user.username}
                          </Link>
                        </div>
                      </td>
                      <td className="py-2 px-4 w-2/6">{record.user_score}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <SpaceBackground />
      <div className="relative min-h-screen text-white">
        <main className="max-w-7xl mx-auto p-6">
          <h1 className="text-4xl font-bold mb-8 text-blue-400">Leaderboard</h1>
          {renderContent()}
        </main>
      </div>
    </>
  );
};

export default UserLeaderboard;