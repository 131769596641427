import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { fetchSingleQuest } from "./QuestsActions";
import QuestDetail from './QuestDetail';
import QuestStats from './QuestStats';
import SpaceBackground from '../SpaceBackground';

const Quest = ({ isAuthenticated, fetchSingleQuest }) => {
  const { questSlug } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const storeQuest = useSelector(state => {
    // Extract prefix from the slug
    const [prefix] = questSlug?.split('-') || [];
    
    // First check active quests
    const fromResults = state.quests.quests.results?.find(q => q.prefix === prefix);
    // Then check finished quests
    const fromFinished = state.quests.finishedQuests?.find(q => q.prefix === prefix);
    
    return fromResults || fromFinished;
  });

  useEffect(() => {
    const loadQuest = async () => {
      if (!questSlug) return;

      setLoading(true);
      try {
        await fetchSingleQuest(questSlug);
        setError(null);
      } catch (err) {
        console.error("Error fetching quest:", err);
        setError(err.message || 'Failed to load quest');
      } finally {
        setLoading(false);
      }
    };

    loadQuest();
  }, [questSlug, fetchSingleQuest]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center text-white">
        <div className="text-xl">Loading quest...</div>
      </div>
    );
  }

  if (error || !storeQuest) {
    return (
      <div className="min-h-screen flex items-center justify-center text-white">
        <div className="text-xl text-red-500">{error || 'Quest not found'}</div>
      </div>
    );
  }

  const getUserStats = () => {
    if (!storeQuest?.stats) return null;

    try {
      return {
        words_len: storeQuest.submission_stats?.words_len,
        words_added: storeQuest.submission_stats?.words_added,
        value_reward: storeQuest.submission_stats?.value_reward,
        entropy_reduction: storeQuest.submission_stats?.entropy_reduction
      };
    } catch (err) {
      console.error('Error extracting user stats:', err);
      return null;
    }
  };

  return (
    <>
      <SpaceBackground />
      <div className="relative min-h-screen text-white overflow-y-auto">
        <div className="min-h-screen flex flex-col pt-24 px-8">
          <div className="w-full flex justify-center font-sans">
            <div className="relative w-full max-w-6xl space-y-8">
              <QuestDetail 
                quest={storeQuest}
                isAuthenticated={isAuthenticated}
                onHome={false}
                previousAnswer={storeQuest.user_submission}
                containerClassName="w-full"
              />
              {storeQuest.stats && (
                <QuestStats
                  key={`stats-${storeQuest.id}`}
                  stats={storeQuest.stats}
                  userSubmission={getUserStats()}
                  isActive={storeQuest.is_active}
                  quest={storeQuest}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { fetchSingleQuest })(Quest);