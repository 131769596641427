import React, { memo, useMemo } from 'react';

const SpaceBackgroundComponent = () => {
  const isLowEndDevice = () => {
    return !window.deviceMemory || 
           window.deviceMemory < 4 || 
           navigator.hardwareConcurrency < 4;
  };

  const CONFIG = {
    // STARS
    STAR_COUNT: isLowEndDevice() ? 24 : 100,
    TRAIL_PROBABILITY: 0.99,
    MIN_STAR_SIZE: 0.3,
    MAX_STAR_SIZE: 1.5,
    MIN_DISTANCE: 20,
    MAX_DISTANCE: 60,
    // SPEED
    MIN_ANIMATION_TIME: 40,
    MAX_EXTRA_TIME: 20,
    // TRAILS
    TRAIL_LENGTH: 3,
    TRAIL_MIN_LENGTH: 2,
    TRAIL_MAX_LENGTH: 5,
    TRAIL_START_WIDTH: 3,
    TRAIL_END_WIDTH: 0.5,
    RED_STAR_CHANCE: 0.2,
    YELLOW_STAR_CHANCE: 0.3,
    OPACITY_MIN: 0.25,
    OPACITY_MAX: 1,
    // REGENERATION
    REGENERATION_OFFSET: -5,
    // NEBULAE
    NEBULA_COUNT: 5,
    MIN_NEBULA_DISTANCE: 30,
    NEBULA_MIN_SIZE: 100,
    NEBULA_MAX_SIZE: 300,
    NEBULA_ANIMATION_MIN_TIME: 200,
    NEBULA_ANIMATION_MAX_TIME: 250,
    NEBULA_MOVE_SCALE: 0.25, // Reduced movement scale for smoother motion
  };

  const stars = useMemo(() => Array(CONFIG.STAR_COUNT).fill().map(() => {
    const angle = Math.random() * Math.PI * 2;
    const distance = CONFIG.MIN_DISTANCE + Math.random() * (CONFIG.MAX_DISTANCE - CONFIG.MIN_DISTANCE);
    const x = 50 + Math.cos(angle) * distance;
    const y = 50 + Math.sin(angle) * distance;
    const size = CONFIG.MIN_STAR_SIZE + Math.random() * (CONFIG.MAX_STAR_SIZE - CONFIG.MIN_STAR_SIZE);
    
    const colorRoll = Math.random();
    const color = colorRoll < CONFIG.RED_STAR_CHANCE ? '#ff8a8a' : 
                 colorRoll < (CONFIG.RED_STAR_CHANCE + CONFIG.YELLOW_STAR_CHANCE) ? '#f7cf06' : 
                 '#ffffff';
    return {
      angle,
      x,
      y,
      size,
      color,
      hasTrail: Math.random() < CONFIG.TRAIL_PROBABILITY,
      animationTime: CONFIG.MIN_ANIMATION_TIME + Math.random() * CONFIG.MAX_EXTRA_TIME,
      delay: CONFIG.REGENERATION_OFFSET,
      opacity: CONFIG.OPACITY_MIN + Math.random() * (CONFIG.OPACITY_MAX - CONFIG.OPACITY_MIN),
      trailLength: CONFIG.TRAIL_MIN_LENGTH + Math.random() * (CONFIG.TRAIL_MAX_LENGTH - CONFIG.TRAIL_MIN_LENGTH)
    };
  }), []);

  const generateNebula = (existingPositions = []) => {
    let attempts = 0;
    const maxAttempts = 20;
    
    while (attempts < maxAttempts) {
      const angle = Math.random() * Math.PI * 2;
      const distance = CONFIG.MIN_DISTANCE + Math.random() * (CONFIG.MAX_DISTANCE - CONFIG.MIN_DISTANCE);
      const cx = 50 + Math.cos(angle) * distance;
      const cy = 50 + Math.sin(angle) * distance;
      
      // Maintain movement direction aligned with spawn position
      const dirX = Math.cos(angle) * CONFIG.NEBULA_MOVE_SCALE;
      const dirY = Math.sin(angle) * CONFIG.NEBULA_MOVE_SCALE;

      const position = {
        cx,
        cy,
        angle,
        dirX,
        dirY,
        rotation: Math.random() * 360,
        size: CONFIG.NEBULA_MIN_SIZE + Math.random() * (CONFIG.NEBULA_MAX_SIZE - CONFIG.NEBULA_MIN_SIZE),
        imageIndex: Math.floor(Math.random() * 3) + 1,
        delay: CONFIG.REGENERATION_OFFSET,
        animationTime: CONFIG.NEBULA_ANIMATION_MIN_TIME + 
                      Math.random() * (CONFIG.NEBULA_ANIMATION_MAX_TIME - CONFIG.NEBULA_ANIMATION_MIN_TIME)
      };

      const isTooClose = existingPositions.some(existing => {
        const dx = existing.cx - position.cx;
        const dy = existing.cy - position.cy;
        return Math.sqrt(dx * dx + dy * dy) < CONFIG.MIN_NEBULA_DISTANCE;
      });

      if (!isTooClose) {
        return position;
      }

      attempts++;
    }

    // Fallback position if no valid position found after max attempts
    return {
      cx: 50,
      cy: 50,
      angle: 0,
      dirX: CONFIG.NEBULA_MOVE_SCALE,
      dirY: CONFIG.NEBULA_MOVE_SCALE,
      rotation: Math.random() * 360,
      size: CONFIG.NEBULA_MIN_SIZE,
      imageIndex: Math.floor(Math.random() * 3) + 1,
      delay: Math.random() * CONFIG.REGENERATION_OFFSET,
      animationTime: CONFIG.NEBULA_ANIMATION_MIN_TIME
    };
  };

  const nebulae = useMemo(() => {
    const positions = [];
    for (let i = 0; i < CONFIG.NEBULA_COUNT; i++) {
      positions.push(generateNebula(positions));
    }
    return positions;
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none z-[-1]"
         style={{ background: 'linear-gradient(to bottom, #030312 0%, #050520 40%, #000000 100%)' }}>
      <svg className="absolute w-full h-full" xmlns="http://www.w3.org/2000/svg">
        <defs>
        {['White', 'Red', 'Yellow'].map(color => (
          <g key={color}>
            <linearGradient
              id={`starTrail${color}Left`}
              x1="0%"
              y1="50%"
              x2="100%"
              y2="50%"
            >
              <stop offset="0%" stopColor={color === 'White' ? '#ffffff' : 
                                      color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0.9" />
              <stop offset="10%" stopColor={color === 'White' ? '#ffffff' : 
                                      color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0.7" />
              <stop offset="50%" stopColor={color === 'White' ? '#ffffff' : 
                                        color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0.3" />
              <stop offset="100%" stopColor={color === 'White' ? '#ffffff' : 
                                        color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id={`starTrail${color}Right`}
              x1="100%"
              y1="50%"
              x2="0%"
              y2="50%"
            >
              <stop offset="0%" stopColor={color === 'White' ? '#ffffff' : 
                                      color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0.9" />
              <stop offset="10%" stopColor={color === 'White' ? '#ffffff' : 
                                      color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0.7" />
              <stop offset="50%" stopColor={color === 'White' ? '#ffffff' : 
                                        color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0.3" />
              <stop offset="100%" stopColor={color === 'White' ? '#ffffff' : 
                                        color === 'Red' ? '#ff8a8a' : '#fff4bd'} 
                    stopOpacity="0" />
            </linearGradient>
          </g>
        ))}
        </defs>
        
        {nebulae.map((nebula, i) => {
          // Calculate center points for rotation with limited decimal places
          const centerX = Number((nebula.cx + nebula.size/2).toFixed(2));
          const centerY = Number((nebula.cy + nebula.size/2).toFixed(2));

          return (
            <image
              key={i}
              href={`/nebula${nebula.imageIndex}.png`}
              x={`${nebula.cx}%`}
              y={`${nebula.cy}%`}
              width={nebula.size}
              height={nebula.size}
              opacity="0.3"
              transform={`rotate(${Math.round(nebula.rotation)} ${centerX} ${centerY})`}
              style={{
                animation: `nebulaMove-${i} ${nebula.animationTime}s linear infinite`,
                animationDelay: `${nebula.delay}s`,
                transform: 'translate3d(0,0,0)',
              }}
            />
          );
        })}
        
        {stars.map((star, i) => {
          const isRightSide = star.x > 50;
          const gradientId = `starTrail${star.color === '#ffffff' ? 'White' : 
                                       star.color === '#ff8a8a' ? 'Red' : 'Yellow'}${isRightSide ? 'Right' : 'Left'}`;
          
          return (
            <g key={i} style={{ willChange: 'transform' }}>
              {star.hasTrail && (
                <line
                  x1={`${star.x}%`}
                  y1={`${star.y}%`}
                  x2={`${star.x - Math.cos(star.angle) * star.trailLength}%`}
                  y2={`${star.y - Math.sin(star.angle) * star.trailLength}%`}
                  stroke={`url(#${gradientId})`}
                  strokeWidth={star.size * CONFIG.TRAIL_START_WIDTH}
                  strokeLinecap="round"
                  style={{
                    animation: `starMove ${star.animationTime}s linear infinite`,
                    animationDelay: `${star.delay}s`,
                    transformOrigin: '50% 50%',
                    transform: 'translate3d(0,0,0)',
                    '--trail-end-width': `${star.size * CONFIG.TRAIL_END_WIDTH}px`
                  }}
                  className="star-trail"
                />
              )}
              <circle
                cx={`${star.x}%`}
                cy={`${star.y}%`}
                r={star.size}
                fill={star.color}
                style={{
                  animation: `starMove ${star.animationTime}s linear infinite`,
                  animationDelay: `${star.delay}s`,
                  transformOrigin: '50% 50%',
                  opacity: star.opacity,
                  transform: 'translate3d(0,0,0)'
                }}
              />
            </g>
          );
        })}
      </svg>
      <style>
      {nebulae.map((nebula, i) => `
        @keyframes nebulaMove-${i} {
          0%, 100% {
            transform: scale(1) translate3d(0, 0, 0);
            opacity: 0;
          }
          5% {
            opacity: 0.3;
          }
          45%, 55% {
            opacity: 0.3;
            transform: scale(1) translate3d(${nebula.dirX * 100}%, ${nebula.dirY * 100}%, 0);
          }
          95% {
            opacity: 0;
            transform: scale(1) translate3d(${nebula.dirX * 200}%, ${nebula.dirY * 200}%, 0);
          }
        }
      `).join('\n')}

      {`
      .star-trail {
        vector-effect: non-scaling-stroke;
      }
      
      @keyframes starMove {
        0% { 
          transform: scale(1) translate3d(0,0,0);
          stroke-width: var(--trail-end-width);
          opacity: 0;
        }
        5% {
          stroke-width: var(--trail-start-width);
          opacity: 1;
        }
        95% {
          opacity: 1;
        }
        100% { 
          transform: scale(3) translate3d(0,0,0);
          stroke-width: var(--trail-end-width);
          opacity: 0;
        }
      }
      
      @keyframes float {
        0%, 100% { transform: translate3d(0,0,0); }
        50% { transform: translate3d(20px,20px,0); }
      }
      `}
      </style>
    </div>
  );
};

export default memo(SpaceBackgroundComponent);