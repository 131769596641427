import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signupNewUser } from './SignupActions';
import { login } from '../login/LoginActions';
import { submitQuest } from '../quests/QuestsActions';
import SpaceBackground from '../SpaceBackground';
import { ArrowRight, UserPlus } from 'lucide-react';
import { toast } from 'react-toastify';

const Signup = ({ signupNewUser, login, submitQuest, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    re_password: ''
  });
  const navigate = useNavigate();

  const { username, password, re_password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === re_password) {
      try {
        await signupNewUser(formData);
        const loginSuccess = await login({ username, password });
        if (loginSuccess) {
          const guestAnswer = localStorage.getItem('guestAnswer');
          const guestQuestId = localStorage.getItem('guestQuestId');
          if (guestAnswer && guestQuestId) {
            await submitQuest(guestQuestId, guestAnswer);
            localStorage.removeItem('guestAnswer');
            localStorage.removeItem('guestQuestId');
          }
          navigate('/');
        } else {
          toast.error("Error with logind after signup");
        }
      } catch (error) {
        toast.error("Error during signup: " + error.message);
      }
    } else {
      toast.error("Passwords don't match");
    }
  };

  if (isAuthenticated) {
    navigate('/dashboard');
    return null;
  }

  return (
    <>
      <SpaceBackground />
      <div className="relative flex items-center justify-center min-h-screen text-white">
        <div className="bg-gray-800 bg-opacity-80 p-8 rounded-lg shadow-lg w-full max-w-md">
          <h1 className="text-3xl font-bold mb-6 text-blue-400">Join Cosmic Quests</h1>
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="username">
                Username
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Choose a username"
                name="username"
                value={username}
                onChange={onChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={onChange}
                minLength="6"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-bold mb-2" htmlFor="re_password">
                Confirm Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="re_password"
                type="password"
                placeholder="Confirm your password"
                name="re_password"
                value={re_password}
                onChange={onChange}
                minLength="6"
                required
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                type="submit"
              >
                <UserPlus className="mr-2" size={18} />
                Sign Up
              </button>
              <Link
                to="/login"
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              >
                Already have an account? <ArrowRight className="inline ml-1" size={16} />
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { signupNewUser, login, submitQuest })(Signup);