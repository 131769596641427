import React from 'react';

export const Alert = ({ children }) => (
  <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
    {children}
  </div>
);

export const AlertTitle = ({ children }) => (
  <h4 className="font-bold mb-2">{children}</h4>
);

export const AlertDescription = ({ children }) => (
  <p>{children}</p>
);

export const AlertDialog = ({ children }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white rounded-lg p-6">
      {children}
    </div>
  </div>
);

export const AlertDialogAction = ({ children, onClick }) => (
  <button 
    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    onClick={onClick}
  >
    {children}
  </button>
);